<template>
    <router-link
            class="no-underline disable-select no-link-color"
            :to="$variables.v.navigation.profile_id + user.userID">
        <v-avatar size="24">
            <img :src="c_valid_picture"
                 @error="valid_picture = false"
                 alt=""
                 loading="lazy">
        </v-avatar>
        <span class="ml-1 no-link-color font-weight-bold" :class="$variables.v.roles.find(x => x.id === user.roleID).color">{{user.username}}</span>
    </router-link>
</template>

<script>
    export default {
        props   : ['user'],
        data() {
            return {
                valid_picture: true,
            }
        },
        computed: {
            c_valid_picture() {
                return this.valid_picture ? this.user.avatar_url : this.$variables.v.profile_default_avatar;
            },
        }
    }
</script>